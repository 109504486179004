import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import type { DateRangeString } from '~/types/dates';

export interface GlobalFilters {
  period: DateRangeString;
}

type FilterKey = keyof GlobalFilters;

export const globalFilters = atom<GlobalFilters>({
  period: [],
});

export function useGetFilterValue(key: FilterKey) {
  const filters = useAtomValue(globalFilters);
  return useMemo(() => filters[key], [filters, key]);
}

export function useSetGlobalPeriodFilter() {
  const setFilters = useSetAtom(globalFilters);

  return useCallback(
    (period: DateRangeString) => {
      setFilters((prev) => ({ ...prev, period }));
    },
    [setFilters],
  );
}
